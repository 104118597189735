/** Set to 100% for BS center align **/
body, html {
  height: 100%;
}

.pink {
  background: #000;
}

.green {
  background: #A2FF00;
}

.black {
  background: #000;
}

a:hover, a:active, a:focus, a {
  outline: 0;
  text-decoration: none;
}

input::-moz-focus-inner {
  border: 0;
}

.relative {
  position: relative;
}

/** Video **/

.video-wrapper .video {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  padding: 10px;
}

.video {
  display: none;
}

.video-fallback {
  display: block;
  padding: 10px;
}

.mask {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .video {
    display: block;
  }

  .video-fallback {
    display: none;
  }


}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}
